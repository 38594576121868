import React, { useState,useEffect } from "react";
import { Switch, CircularProgress, Box } from "@mui/material";
import axios from "axios";

const ProductToggle = ({ productId, initialIsActive }) => {
  const [isActive, setIsActive] = useState(initialIsActive);
  // console.log(initialIsActive);
  const [loading, setLoading] = useState(false); // State to track loading status
  // Sync local state with updated prop
  useEffect(() => {
    setIsActive(initialIsActive); // Update `isActive` whenever `initialIsActive` changes
  }, [initialIsActive]);
  
  const handleToggleChange = async (event) => {
    const newIsActive = event.target.checked ? 1 : 0;
    setLoading(true); // Set loading to true while the request is in progress
    try {
      await axios.put(
        `${process.env.REACT_APP_BASEURL}/admin/product/changestatus`,
        {
          product_id: parseInt(productId),
          is_active: newIsActive,
          user_id: 2,
        }
      );
      setIsActive(newIsActive); // Update the state if the request is successful
    } catch (error) {
      console.error("Failed to update product status:", error);
    } finally {
      setLoading(false); // Reset loading state once request completes
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Switch
          checked={isActive === 1}
          onChange={handleToggleChange}
          color="primary"
        />
      )}
    </>
  );
};

export default ProductToggle;
